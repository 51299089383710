import React from "react"

const LogoKh = ({className = "", fill = "url(#linear-gradient)"}: {fill?: string, className?: string}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.023 149" className={className}>
            <defs>
                <linearGradient id="linear-gradient" x1="0.076" y1="0.09" x2="1.412" y2="0.068"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f5bf47"/>
                    <stop offset="1" stopColor="#c69e47"/>
                </linearGradient>
            </defs>
            <g id="Group_5" data-name="Group 5" transform="translate(-734.988 -431)" opacity="0.881">
                <path fill={fill} id="Union_3" data-name="Union 3"
                      d="M71.012,147V37h-.022a5.5,5.5,0,0,0-10.956,0h-.022v96a4,4,0,0,1-8,0V26H39.33A19.177,19.177,0,0,1,21.02,40.042,18.819,18.819,0,0,1,2,21.028,18.982,18.982,0,0,1,20.812,2.009V2h108.4v.009a18.982,18.982,0,0,1,18.812,19.019A18.819,18.819,0,0,1,129,40.042,19.177,19.177,0,0,1,110.693,26H99.012V133a4,4,0,0,1-8,0V37h-.083a6,6,0,0,0-11.834,0h-.083V147a4,4,0,1,1-8,0ZM129,32a10.994,10.994,0,1,0,0-21.988V10H21.02v.008A10.994,10.994,0,1,0,21.02,32a10.81,10.81,0,0,0,9.771-6H21.011a4,4,0,0,1,0-8h108a4,4,0,1,1,0,8h-9.779A10.81,10.81,0,0,0,129,32Z"
                      transform="translate(732.988 429)"/>
                <rect fill={fill} id="Rectangle_3" data-name="Rectangle 3" width="81" height="8" rx="4"
                      transform="translate(773 471) rotate(90)"/>
                <rect fill={fill} id="Rectangle_4" data-name="Rectangle 4" width="81" height="8" rx="4"
                      transform="translate(851 471) rotate(90)"/>
            </g>
        </svg>

    )

}

export default LogoKh;