import React from "react"
import './src/styles/global.css'
import type { GatsbyBrowser } from "gatsby"
import Layout from "./src/components/layout/layout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element}) => {

    return (
        <Layout>
            {element}
        </Layout>
    )

}

const transitionDelay = 400

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = () => {

    window.setTimeout(() => {
        // @ts-ignore
        window["scroll"].scrollTo( 'top', {
            'offset': 0,
            'duration': 0
        } );
    }, transitionDelay);
    return false;
}