import React from "react";
import TransitionWrapper from "../transition-wrapper/transition-wrapper";
import SmoothScroll from "../smooth-scroll/smooth-scroll";
import NavigationHeader from "../navigation-header/navigation-header";

const Layout = ({children}: {children: React.ReactNode}) => {

    return (
        <>
            <SmoothScroll/>
            <NavigationHeader/>
            <main id={"site_content"}>
                <TransitionWrapper>
                    {children}
                </TransitionWrapper>
            </main>
        </>
    )

}

export default Layout