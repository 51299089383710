import React, {useEffect} from "react";
import {useLocation} from "@reach/router";
import LocomotiveScroll from "locomotive-scroll";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import "./locomotive.css"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

const SmoothScroll = () => {

    const scroll: {container: string} = {
        container: "#site_content",
    }

    const location = useLocation();

    useEffect(() => {
        let locomotiveScroll: LocomotiveScroll;

        locomotiveScroll = new LocomotiveScroll({
            el: document.querySelector(scroll.container) as HTMLElement,
            smooth: true,
            getDirection: true,
            touchMultiplier: 2.5,
            lerp: 0.15
        })
        locomotiveScroll.update();

        // @ts-ignore
        window['scroll'] = locomotiveScroll

        locomotiveScroll.on("scroll", func => {
            ScrollTrigger.update();
            // document.documentElement.setAttribute("data-direction", func.direction)
        })



        ScrollTrigger.scrollerProxy(scroll.container, {
            scrollTop(value) {
                // @ts-ignore
                return arguments.length ? locomotiveScroll.scrollTo(value ?? 0) : locomotiveScroll.scroll.instance.scroll.y;
            },
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            }
        })

        ScrollTrigger.addEventListener("refresh", () => locomotiveScroll.update());
        ScrollTrigger.defaults({ scroller: scroll.container });

        ScrollTrigger.refresh();

        new ResizeObserver(() => {
            locomotiveScroll.update();
        }).observe(document.querySelector(scroll.container) as Element)

        return () => {
            if (locomotiveScroll) locomotiveScroll.destroy();
        }
    }, []);

    useEffect(() => {

        ScrollTrigger.getAll().forEach(t => t.kill())

        setTimeout(() => {
            document.querySelectorAll(`[data-reveal="true"]`).forEach((element) => {
                gsap.set(element, {autoAlpha: 0});

                switch (element.getAttribute("data-object")) {
                    case "text":
                        let split = new SplitText(element, {
                            type: "lines",
                            linesClass: 'lines',
                        });
                        gsap.set(split.lines, {
                            'will-change': 'opacity, transform',
                            transformStyle: 'preserve-3d',
                            y: 20,
                            autoAlpha: 0
                        })
                        ScrollTrigger.create({
                            trigger: element,
                            scrub: true,
                            start: 'top bottom-=10%',
                            onEnter: () => {
                                gsap.to([element, split.lines], {
                                    duration: 1.5,
                                    x: 0,
                                    y: 0,
                                    autoAlpha: 1,
                                    ease: "expo",
                                    overwrite: "auto",
                                    delay: parseFloat(element.getAttribute("data-delay") ?? "0"),
                                    stagger: parseFloat(element.getAttribute("data-stagger") ?? "0")
                                })
                            },
                        });
                        break;
                    case "simple":
                        gsap.set(element, {
                            'will-change': 'opacity, transform',
                            transformStyle: 'preserve-3d',
                            y: 20,
                            autoAlpha: 0
                        })
                        ScrollTrigger.create({
                            trigger: element,
                            onEnter: () => {
                                gsap.to(element, {
                                        duration: 1.25,
                                        x: 0,
                                        y: 0,
                                        autoAlpha: 1,
                                        ease: "expo",
                                        overwrite: "auto",
                                        delay: parseFloat(element.getAttribute("data-delay") ?? "0"),
                                        stagger: parseFloat(element.getAttribute("data-stagger") ?? "0")
                                    })
                            }
                        });
                        break;
                    case "line":
                        gsap.set(element, {
                            'will-change': 'opacity, transform',
                            transformStyle: 'preserve-3d',
                            scaleX: 0,
                            transformOrigin: 'left',
                            autoAlpha: 0
                        })
                        ScrollTrigger.create({
                            trigger: element,
                            onEnter: () => {
                                gsap.to(element, {
                                        duration: 1.5,
                                        scaleX: 1,
                                        autoAlpha: 1,
                                        ease: "expo",
                                        overwrite: "auto",
                                        delay: parseFloat(element.getAttribute("data-delay") ?? "0"),
                                        stagger: parseFloat(element.getAttribute("data-stagger") ?? "0")
                                    })
                            }
                        });
                        break;
                }
            });
        }, 500)

        // ScrollTrigger.create({
        //     trigger: document.querySelector(".image_crazy") as Element,
        //     onEnter: function() { console.log("entered") },
        //     onEnterBack: function() { console.log("enter back") },
        //     onLeave: function() { console.log("leave") }
        // });
    }, [location.pathname])



    return (
        <>
        </>
    )

}

export default SmoothScroll;