import { useLocation } from "@reach/router"
import React, {CSSProperties, Fragment} from "react"
import {
    TransitionGroup,
    Transition as ReactTransition, TransitionStatus,
} from "react-transition-group"

const timeout = 400

const getTransitionStyles: (status: TransitionStatus) => CSSProperties = (status) => {
    switch (status) {
        case "entering":
            return {
                position: 'absolute',
                transform: "translateY(12px)",
                opacity: 0,
            }
        case "entered":
            return {
                transition: `opacity ${timeout}ms ease-in-out, transform ${timeout}ms cubic-bezier(.29,.77,.44,.89)`,
                transform: "translateY(0)",
                opacity: 1,
            }
        case "exiting":
            return {
                transition: `opacity 250ms ease-in-out 100ms, transform ${timeout}ms cubic-bezier(1,.01,.44,.89)`,
                transform: "translateY(-12px)",
                opacity: 0
            }
        default:
            return {}
    }
}

const TransitionWrapper = ( props: {children: React.ReactNode} ) =>  {

    const { children } = props

    const location = useLocation();

    return (
        <TransitionGroup component={Fragment}>
            <ReactTransition
                key={location.pathname}
                timeout={{
                    enter: timeout,
                    exit: timeout,
                }}
            >
                {(status: TransitionStatus) => (
                    <>
                        <div
                            style={{
                                ...getTransitionStyles(status)
                            }}
                        >
                            {children}
                        </div>
                    </>
                )}
            </ReactTransition>
        </TransitionGroup>
    )
}

export default TransitionWrapper